<template>
  <div class="app__login">
    <form @submit.prevent="forgetHandler">
      <div id="logo">
        <img src="../assets/images/logo.svg" alt="Логотип" />
      </div>
      <h1>ДИСПЕТЧЕРСКАЯ</h1>
      <div v-if="forgetSuccess">
        <h2 style="margin-bottom: 20px">
          В ближайшее время ваш диспетчер отправит данные для входа на указанный
          телефон
        </h2>
        <Lottie :options="defaultOptions" :width="128" :height="128" />
      </div>
      <div v-else>
        <h2>Введите номер телефона, на который был зарегистрирован аккаунт</h2>
        <div class="form-input">
          <!-- <label for="login">Ваш логин (номер телефона)</label> -->
          <label for="login">Ваш номер телефона</label>
          <masked-input
            v-model="login"
            type="text"
            id="login"
            mask="\+\7(111) 111-11-11"
            placeholder="+7(000) 000-00-00"
            required="required"
          />
        </div>
        <form-button
          title="ДАЛЕЕ"
          style="margin-top: 20px"
          class="button__yellow"
          :disabled="disabled"
        ></form-button>
      </div>
      <ui-message
        @messageClose="messageClose"
        :message="message"
        :messageProp="messageProp"
      />
      <div id="comeBack">
        <router-link to="/auth">Вернуться</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import FormButton from "../components/form/form-button.vue";
import UiMessage from "../components/ui/ui-message.vue";
// MaskedInput
import MaskedInput from "vue-masked-input";
// Animation
import Lottie from "vue-lottie";
import animationData from "../assets/animations/check-circle.js";

export default {
  name: "ForgetPage",

  components: {
    FormButton,
    UiMessage,
    MaskedInput,
    Lottie
  },

  data() {
    return {
      login: "",
      disabled: false,
      message: false,
      messageProp: {},
      forgetSuccess: false,
      defaultOptions: { animationData: animationData, loop: false },
      animationSpeed: 1
    };
  },

  computed: {
    clearLogin() {
      let clearLogin = "";
      if (this.login) {
        clearLogin = this.login.replace(/[^0-9]/g, "");
      }
      return clearLogin;
    }
  },

  methods: {
    clearForm() {
      this.login = "";
      this.disabled = false;
    },

    forgetHandler() {
      this.disabled = true;
      this.axios
        .post(`/driver/request/password/${this.clearLogin}`)
        .then((response) => {
          console.log(response);
          if (response.data.response) {
            this.forgetSuccess = true;
          } else if (response.data.error === "DRIVER_NOT_FOUND") {
            (this.message = true),
              (this.messageProp = {
                class: "alert__danger",
                title: "Ошибка",
                text: "Такой номер телефона не зарегистрирован"
              });
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.clearForm();
        });
    },

    messageClose() {
      this.message = false;
      this.messageProp = {};
    }
  }
};
</script>

<template>
  <button :type="type" :class="className" @click="click" :value="value">
    {{ title }}
  </button>
</template>

<script>
export default {
  name: "FormButton",

  props: {
    title: String,
    type: {
      type: String,
      default: "submit"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: 1
    }
  },

  computed: {
    className() {
      return {
        disabled: this.disabled
      };
    }
  },

  methods: {
    click(event) {
      this.$emit("click", event.target.value);
    }
  }
};
</script>

<style lang="scss">
button {
  @include button;

  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}

.button__yellow {
  background: $color-yellow;
  color: $color-black;
  cursor: pointer;

  &.big {
    @include font-family-montserrat;
    font-size: 36px;
    font-weight: 300;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: center;
    height: 104px;
    padding: 0 50px;
    @media (max-width: 1199.98px) {
      font-size: 30px;
      line-height: 35px;
    }
    @media (max-width: 767.98px) {
      font-size: 25px;
      line-height: 30px;
      height: 80px;
      padding: 0 20px;
    }
    @media (max-width: 575.98px) {
      font-size: 21px;
      line-height: 24px;
      height: 62px;
      padding: 0 20px;
    }
  }

  &:hover {
    background: $color-yellow-hover;
  }
}

.button__red {
  background: $color-red;
  color: $color-white;
  cursor: pointer;

  &:hover {
    background: $color-red-hover;
  }
}

.button__clear {
  @include flex;
  background: #f7f7f7;
  border: 1px solid $color-grey-light;
  border-radius: 8px;
  cursor: pointer;
  height: 32px;
  width: 34px;

  &:hover {
    opacity: 0.7;
  }
}
</style>
